import {
  BankConciliationUploadFileContainer,
  BankConciliationFileUploadInformation,
  BankConciliationFileUploadSelect,
  BankConciliationFileUploadContent,
} from './BankConciliationUploadFile.styles';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillTransfer,
  faCheckDouble,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { useAuth } from 'contexts/auth';
import cashierBankRepository from '../../../../../../repositories/CashierBank';
import { toastr as toast } from 'react-redux-toastr';
import { useBankConciliation } from '../../hooks/useBankConciliation';
import { useFormContext } from 'react-hook-form';

export function BankConciliationFileUpload() {
  const { company } = useAuth();
  const { LoadingComponent, setIsLoading, isLoading } = useLoading();
  const { setValue, getValues, watch } = useFormContext();
  const [selectedBankOption, setSelectedBankOption] = useState(null);
  const dataFile = watch('informationFile');
  const cashierBankId = watch('informationFile.bank');
  const [bankOptions, setBankOptions] = useState([]);
  const [isDisableInputFile, setIsDisableInputFile] = useState(true);
  const [isDisableSelectBank, setIsDisableSelectBank] = useState(false);

  const { processFiles, getMessage, handleValidateBankCode } =
    useBankConciliation(getValues, setValue);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setIsLoading(true);
      const data = await processFiles(acceptedFiles);
      if (data) {
        setValue('informationFile.file', acceptedFiles[0]);
        setValue('informationFile.data', data);
        handleValidateBankCode(selectedBankOption, data, setValue);
      }
      setIsLoading(false);
    },
  });

  const getIcon = () => {
    if (dataFile?.data?.isValid === undefined) {
      return { icon: faMoneyBillTransfer, color: '#1D7391' };
    } else if (dataFile?.data?.isValid) {
      return { icon: faCheckDouble, color: '#5CB85C' };
    } else {
      return { icon: faCircleXmark, color: '#D9534F' };
    }
  };

  const headerFile = watch('informationFile.data.headerFile');

  useEffect(() => {
    const getBanks = async () => {
      if (!company) return;
      if (headerFile?.id) {
        const bank = bankOptions.find(
          (bank) => bank.id === Number(headerFile?.cashierBankId)
        );
        setSelectedBankOption(bank);
        setValue('informationFile.bank', bank.id);
        setIsDisableSelectBank(true)
        return
      }

      try {
        const res = await cashierBankRepository.getCashierBanksByCompany(
          company.id
        );
        const banks = res.filter((bank) => bank.Bank !== null && bank.isActive);

        const banksSerialized = banks.map((bank) => ({
          id: bank?.id,
          code: bank?.Bank?.code,
          label: `${bank?.Bank?.code} - ${bank.description}`,
        }));
        setIsDisableSelectBank(false)
        if (banks.length === 0) {
          toast.error('Nenhum banco encontrado. Tente novamente!');
          return;
        }
        setBankOptions(banksSerialized);
      } catch (err) {
        toast.error('Ocorreu um erro ao carregar os bancos. Tente novamente!');
      }
    };

    getBanks();

    setValue('informationFile.bank', headerFile?.cashierBankId);
  }, [headerFile?.id]);

  useEffect(() => {
    if (selectedBankOption && dataFile?.data) {
      handleValidateBankCode(selectedBankOption, dataFile?.data, setValue);
    }
  }, [selectedBankOption, dataFile?.data]);

  const iconInfo = getIcon();
  return (
    <BankConciliationUploadFileContainer>
      <BankConciliationFileUploadInformation>
        <BankConciliationFileUploadSelect>
          <label htmlFor="type" style={{ width: '100%' }}>
            Selecione o Banco:
            <span style={{ color: 'red' }}>*</span>
          </label>
           <select
        id="bankId"
        name="bankId"
        className="form-control foco-input"
        value={selectedBankOption?.id || ""}
        onChange={(e) => {
          const bank = bankOptions.find(
            (bank) => bank.id === Number(e.target.value)
          );
          setSelectedBankOption(bank);
          setValue('informationFile.bank', bank.id);
          setIsDisableInputFile(false)
        }}
          disabled={isDisableSelectBank}
      >
        <option value={0}>Selecione</option>
        {bankOptions.map(({ id, label }) => (
          <option key={id} value={id}>
            {label}
          </option>
        ))}
      </select>
          <small style={{ color: '#D9534F', fontSize: '12px' }}>
            Apenas bancos ativos e com cadastro completo serão exibidos
          </small>
        </BankConciliationFileUploadSelect>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <small>*Campos obrigatórios</small>
          {dataFile.file && (
            <button
              style={{
                color: '#1D7391',
                fontSize: '12px',
                background: 'none',
                border: 'none',
              }}
              onClick={() => {
                setValue('informationFile.file', null);
                setValue('informationFile.bank', '');
                setValue('informationFile.data', undefined);
                setSelectedBankOption(null);
              }}
            >
              Remover Arquivo: {dataFile?.file?.name}
            </button>
          )}
        </div>
      </BankConciliationFileUploadInformation>

      <div
        {...getRootProps({ className: 'dropzone' })}
        style={{
          border: '2px dashed #cccccc',
          padding: '50px',
          textAlign: 'center',
          opacity: isDisableInputFile ? 0.5 : 1,
          cursor: isDisableInputFile ? 'not-allowed' : 'pointer',
        }}
      >
        <input {...getInputProps()} disabled={isDisableInputFile} />
        <BankConciliationFileUploadContent>
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <>
              <FontAwesomeIcon
                icon={iconInfo.icon}
                style={{ fontSize: '40px', color: iconInfo.color }}
              />
              <strong>{getMessage()}</strong>
            </>
          )}
        </BankConciliationFileUploadContent>
      </div>
    </BankConciliationUploadFileContainer>
  );
}
