import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import Users from 'repositories/Users';
import { BankConciliationRepositoryV2 } from 'v2/repositories/BankConciliationRepository';
import cashierBankRepository from 'repositories/CashierBank';

export const useBankConciliation = (getValues, setValue) => {
  const [bankOptions, setBankOptions] = useState([]);

  const [isFileTypeOFX, setIsFileTypeOFX] = useState(undefined);
  const [data, setData] = useState({
    isFileValid: undefined,
    message: undefined,
    ofx: undefined,
  });

  const typeOptions = [
    { id: 0, label: 'Ambos' },
    { id: 1, label: 'Receber' },
    { id: 2, label: 'Pagar' },
  ];

  const typeOptionsMovement = [
    { id: 0, label: 'Ambos' },
    { id: 1, label: 'Movimentação' },
    { id: 2, label: 'Transferência' },
  ];

  const statusOptions = [
    { id: 0, label: 'Ambos' },
    { id: 2, label: 'Finalizado' },
    { id: 1, label: 'Aberto' },
  ];

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const defaultValues = {
    informationFile: {
      file: null,
      bank: '',
    },
    financialRegister: {
      typeFinancialRegister: 'titulo',
      description: '',
      TransactionInitialDate: '',
      TransactionFinishDate: '',
      transactionType: '',
      transactionStatus: '',
    },
    search: {
      financialRegister: {
        typeFinancialRegister: 'titulo',
        description: '',
        transactionType: 'Ambos',
        transactionStatus: 'Ambos',
        TransactionInitialDate: '',
        TransactionFinishDate: '',
      },
      bankConciliation: {
        initialDate: '',
        finalDate: '',
        selectedUserOptions: '',
        type: 'conciliation',
        selectedBankOptions: '',
      },
    },
    optionsBanks: [],
  };
  const processFiles = async (acceptedFiles) => {
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension === 'ofx') {
          setIsFileTypeOFX(true);

          const response = await handleFileUpload(file);
          if (response) {
            setData(response);
            setValue('informationFile.data', response.ofx);
            setIsFileTypeOFX(response.isFileValid);
            toastr.success(
              'Arquivo importado com sucesso',
              'Clique em "Importar" para continuar a conciliação'
            );
            return response;
          } else {
            setIsFileTypeOFX(false);
            toastr.error('Erro ao importar o arquivo', response.message);
          }
        } else {
          setIsFileTypeOFX(false);
          toastr.warning(
            'O arquivo selecionado não é da extensão .ofx. Verifique e tente novamente'
          );
        }
      }
    }
  };

  const handleValidateBankCode = (selectedBankOption, dataOFX, setValue) => {
    if (!dataOFX || !dataOFX.headerFile) return;
    const errorMessage = getValues(
      'informationFile.data.cashierBankValidation'
    );

    if (selectedBankOption) {
      if (
        Number(dataOFX?.headerFile?.bankCode) ===
        Number(selectedBankOption.code)
      )
        return;

      if (
        dataOFX.headerFile?.id &&
        dataOFX.headerFile?.cashierBankId !== selectedBankOption
      ) {
        setValue('informationFile.bank', dataOFX.headerFile?.cashierBankId);
        toastr.success(
          'Este arquivo já foi importado anteriormente. Você pode continuar a conciliação.'
        );
        return;
      }

      setValue('informationFile.bank', '');
      toastr.error(
        'Banco inválido.',
        errorMessage?.message || 'O código do banco não corresponde ao arquivo.'
      );
    }
  };

  /* ${data.ofx.bankInformation.bankName} */
  const getMessage = () => {
    const data = getValues('informationFile.data');

    if (data?.isValid === undefined) {
      return (
        <>
          <p style={{ fontWeight: 'bold' }}>
            <strong style={{ textDecoration: 'underline' }}>Clique aqui</strong>{' '}
            ou arraste para importar o arquivo .OFX
          </p>
        </>
      );
    }

    if (!data?.isValid) {
      return data?.message || 'Arquivo inválido. Tente novamente.';
    }

    if (data?.isValid) {
      return 'Arquivo importado com sucesso!';
    }

    return 'Arquivo não suportado';
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (file) => {
    try {
      const bankId = getValues('informationFile.bank');

      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!file || fileExtension !== 'ofx') {
        throw new Error('Arquivo inválido. Por favor, envie um arquivo OFX.');
      }

      const base64File = await convertFileToBase64(file);
      const cleanBase64File = base64File.split(',')[1];

      const response =
        await BankConciliationRepositoryV2.sendOFXForReconciliation(
          bankId,
          cleanBase64File
        );

      return response;
    } catch (error) {
      console.error('Erro ao carregar o arquivo:', error);
      toastr.error(`Erro ao carregar o arquivo: ${error.message}`);
    }
  };
  const removeVinculoTransaction = () => {
    const transactions = getValues('informationFile.data.transactions') || [];
    const transactionsToUpdate = getValues('selectedTransactions') || [];

    const updatedTransactions = transactions.map((transaction) => {
      const matchingTransaction = transactionsToUpdate.find(
        (t) => t.transactionId === transaction.transactionId
      );

      if (matchingTransaction) {
        return { ...transaction, conciled: !transaction.conciled };
      }

      return transaction;
    });

    setValue('informationFile.data.transactions', updatedTransactions, {
      shouldValidate: true,
      shouldDirty: true,
    });

    toastr.success('Vínculo removido com sucesso');
  };

  const markAllAsConciliated = () => {
    const selectedTransactionsConciliation =
      getValues('selectedTransactionsConciliation') || [];
    const selectedTransactions = getValues('selectedTransactions') || [];
    const allTransactions =
      getValues('informationFile.data.transactions') || [];

    const sumAmounts = (transactions) =>
      transactions.reduce((sum, transaction) => {
        const amount = parseFloat(transaction.amount || transaction.total || 0);
        return sum + amount;
      }, 0);

    const totalSelectedTransactions = sumAmounts(selectedTransactions);
    const totalSelectedTransactionsConciliation = sumAmounts(
      selectedTransactionsConciliation
    );

    const total =
      totalSelectedTransactions - totalSelectedTransactionsConciliation;

    if (
      totalSelectedTransactions - totalSelectedTransactionsConciliation ===
      0
    ) {
      const updatedTransactions = allTransactions.map((transaction) => {
        const isSelected = selectedTransactions.some(
          (selected) => selected.transactionId === transaction.transactionId
        );

        if (isSelected) {
          return {
            ...transaction,
            conciled: true,
          };
        }
        return transaction;
      });

      setValue('informationFile.data.transactions', updatedTransactions, {
        shouldValidate: true,
        shouldDirty: true,
      });
      return true;
    }
    toastr.warning(
      'Não foi possível conciliar',
      `A diferença entre os valores selecionados não é igual a zero. Por favor, verifique a diferença: ${formatter.format(
        total
      )}`
    );
    return false;
  };

  const handleFinancialRegisterInicialDateChange = (date) => {
    const currentDate = new Date();

    const initialDate = new Date(currentDate);
    initialDate.setDate(currentDate.getDate() - 7);

    const formatDate = (date) => {
      return date.toISOString().split('T')[0];
    };

    const formattedInitialDate = formatDate(initialDate);
    const formattedFinalDate = formatDate(currentDate);

    return {
      initialDate: formattedInitialDate,
      finalDate: formattedFinalDate,
    };
  };

  const saveOrUpdateConciliation = async (
    transactions,
    bills,
    cashierBankId,
    userId,
    companyId
  ) => {
    const headerFiles = getValues('informationFile.data.headerFile');

    try {
      const headerFile = {
        ...headerFiles,
        cashierBankId,
        userId,
        companyId,
      };

      if (headerFile?.id) {
        await BankConciliationRepositoryV2.updateConciliation({
          headerFile,
          transactions,
          bills,
        });
        toastr.success('Conciliação Atualizada com sucesso');
      } else {
        await BankConciliationRepositoryV2.saveConciliation({
          headerFile,
          transactions,
          bills,
        });
        toastr.success('Conciliação Realizada com sucesso');
      }
    } catch (error) {
      console.error('Erro ao salvar/atualizar conciliação:', error);
      toastr.error('Erro ao realizar a conciliação');
    }
  };
  const loadUsers = async (companyId) => {
    try {
      const response = await Users.getByCompanyId(companyId);

      const users = response.filter((user) => user.isActive !== 0);

      return users;
    } catch (err) {
      toastr.error(
        'Erro ao carregar os usuários',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    }
  };

  const loadBanks = async (companyId) => {
    try {
      const response = await cashierBankRepository.getCashierBanksByCompany(
        companyId
      );

      const banks = response.filter(
        (bank) => bank.Bank !== null && bank.cashierBankTypeId !== 2
      );

      const serializedBanks = banks.map(
        (bank) => ({
          label: bank.description,
          value: bank.id,
        }),
        [banks]
      );

      serializedBanks.unshift({ value: '*', label: 'Todos' });

      return serializedBanks;
    } catch (err) {
      toastr.err(
        'Erro ao carregar os bancos',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    }
  };

  return {
    bankOptions,
    setBankOptions,
    isFileTypeOFX,
    setIsFileTypeOFX,
    data,
    processFiles,
    getMessage,
    removeVinculoTransaction,
    markAllAsConciliated,
    handleFinancialRegisterInicialDateChange,
    saveOrUpdateConciliation,
    loadUsers,
    loadBanks,
    typeOptions,
    typeOptionsMovement,
    statusOptions,
    handleValidateBankCode,
  };
};
