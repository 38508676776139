import api from '../services/api';

const sendOFXForReconciliation = async (cashierBankId, base64) => {
  try {
    const response = await api.post('bank-conciliation/verify-ofx', {
      cashierBankId,
      base64,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const saveConciliation = async ({ headerFile, transactions, bills }) => {
  try {
    const response = await api.post('bank-conciliation/save-conciliation', {
      headerFile,
      transactions,
      bills,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};
const updateConciliation = async ({ headerFile, transactions, bills }) => {
  try {
    const response = await api.put(
      `bank-conciliation/update-conciliation/${headerFile.id}`,
      {
        headerFile,
        transactions,
        bills,
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const listBanksConciliations = async (params) => {
  try {
    const response = await api.get('/bank-conciliation', { params });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getById = async (id, companyId) => {
  try {
    const response = await api.get(
      `/bank-conciliation/consult-conciliation/${id}?companyId=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getBills = async ({
  initialDate,
  finalDate,
  cashierBankId,
  companyId,
  page = 1,
  limit = 10,
  planId = '',
  status = '',
  query = '',
  type = '',
}) => {
  try {
    const params = new URLSearchParams({
      initialDate,
      finalDate,
      cashierBankId,
      companyId,
      page,
      limit,
      planId,
      status,
      query,
      type,
    });

    const response = await api.get(
      `/bank-conciliation/get-bills?${params.toString()}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
const getMovements = async ({
  companyId,
  cashierBankId,
  query = '',
  initialDate,
  finalDate,
  type = '',
  status = '',
  page = 1,
  limit = 10,
}) => {
  try {
    const params = new URLSearchParams({
      companyId,
      cashierBankId,
      query,
      initialDate,
      finalDate,
      type,
      status,
      page,
      limit,
    });

    const response = await api.get(
      `/bank-conciliation/get-movements?${params.toString()}`
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};

const BankConciliationRepositoryV2 = {
  sendOFXForReconciliation,
  saveConciliation,
  listBanksConciliations,
  getBills,
  getMovements,
  updateConciliation,
  getById,
};

export { BankConciliationRepositoryV2 };
