import React, { useState, useEffect } from 'react';
import { toastr as toast } from 'react-redux-toastr';
import { Table } from 'v2/components/Table';
import { FormProvider, useForm } from 'react-hook-form';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { DefaultContentContainer } from 'v2/components/layout';
import { getBreadcrumbData } from './utils';
import { useAuth } from 'contexts/auth';
import { BankConciliationRepositoryV2 } from 'v2/repositories/BankConciliationRepository';

import './styles.css';
import BankConciliationModal from './Modal/BankConciliationModal';
import { useBankConciliationDetailsColumnsConfig } from './hooks/useBankConciliationDetailsColumnsConfig';
import { useBankConciliation } from './hooks/useBankConciliation';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { BankConciliationSearch } from './components/BankConciliationDetails/components/BankConciliationDetailsSearch/BankConciliationSearch';
import { format } from 'date-fns';

export function BankConciliation() {
  const { setIsLoading, isLoading } = useLoading();

  const { companyId } = useAuth();

  const [banksOptions, setBanksOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [isBankConciliationModalOpen, setIsBankConciliationModalOpen] =
    useState(false);
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const prefix = 'bankConciliation';
  const validations = () => {
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      toast.warning('Datas devem ser menores ou iguais a data atual.');
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        toast.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  const methods = useForm({
    defaultValues: {
      informationFile: {
        file: null,
        bank: '',
      },
    },
    search: {
      financialRegister: {
        typeFinancialRegister: 'titulo',
        description: '',
        transactionType: 'Ambos',
        transactionStatus: 'Ambos',
        TransactionInitialDate: '',
        TransactionFinishDate: '',
      },
      bankConciliation: {
        initialDate: '',
        finalDate: '',
        selectedUserOptions: '',
        type: 'conciliation',
        selectedBankOptions: '',
      },
    },
    optionsBanks: [],
    isView: false,
    viewId: null,
  });

  const { configColumnsConciliation } = useBankConciliationDetailsColumnsConfig(
    methods.setValue,
    methods.getValues
  );
  const { loadUsers, loadBanks } = useBankConciliation(
    methods.getValues,
    methods.setValue
  );

  const initialDate = methods.watch(`search.${prefix}.initialDate`);
  const finalDate = methods.watch(`search.${prefix}.finalDate`);
  const type = methods.watch(`search.${prefix}.type`);
  const isView = methods.watch(`isView`);
  const viewId = methods.watch(`viewId`);
  const selectedUserOption = methods.watch(
    `search.${prefix}.selectedUserOptions`
  );
  const selectedBankOption = methods.watch(
    `search.${prefix}.selectedBankOption`
  );

  const getDateConciliation = async () => {
    setIsLoading(true);

    try {
      const [users, banks] = await Promise.all([
        loadUsers(companyId),
        loadBanks(companyId),
      ]);

      setUsersOptions(users);
      setBanksOptions(banks);
    } catch (err) {
      console.error(err);

      toast.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const loadBanksConciliations = async () => {
    setIsLoading(true);

    try {
      const banksIds =
        selectedBankOption &&
        selectedBankOption
          .map((item) => item.value)
          .filter((value) => value !== '*')
          .join(',');

      const response =
        await BankConciliationRepositoryV2.listBanksConciliations({
          companyId,
          initialDate,
          finalDate,
          type,
          bankId: banksIds,
          identifier: selectedUserOption,
          page: currentPage + 1,
          limit: pageLimit,
        });

      setRows(response.data);
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.log(err);
      toast.error(
        'Erro ao carregar as conciliações bancárias',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDateConciliation();
  }, []);

  useEffect(() => {
    if (!!validations()) {
      loadBanksConciliations();
    }
  }, [
    currentPage,
    selectedBankOption,
    type,
    initialDate,
    finalDate,
    selectedUserOption,
  ]);

  const handleLoadingViewData = async () => {
    setIsLoading(true);
    try {
      setIsBankConciliationModalOpen(true);
      const data = await BankConciliationRepositoryV2.getById(viewId);
      const serializedData = {
        headerFile: {
          accountId: data.headerFile[0].accountId,
          accountType: data.headerFile[0].accountType,
          balance: data.headerFile[0].balance,
          bankId: data.headerFile[0].bankId,
          bankName: data.headerFile[0].bankName,
          currency: data.headerFile[0].currency,
        },
        isValid: true,
        transactions: data.transactions.map((transaction) => ({
          amount: transaction.amount,
          checkNumber: transaction.checkNumber,
          conciled: transaction.conciled,
          datePosted: format(new Date(transaction.datePosted), 'MM/dd/yyyy'),
          memo: transaction.memo,
          transactionId: transaction.transactionId,
          type: transaction.type,
        })),
      };
      const finantialRegisterSerialized = {
        count: data.bills.length,
        data: data.bills,
      };
      const finantialMovementsSerialized = {
        count: data.movements.length,
        data: data.movements,
      };
      methods.setValue('informationFile.data', serializedData);
      methods.setValue(
        'selectedTransactions',
        serializedData.transactions.filter(
          (transaction) => transaction.conciled
        )
      );
      methods.setValue('dataFinancialRegister', finantialRegisterSerialized);
      methods.setValue('selectedTransactionsConciliation', [
        ...data.bills,
        ...data.movements,
      ]);
      methods.setValue('dataFinancialMovements', finantialMovementsSerialized);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isView) {
      handleLoadingViewData();
    }
  }, [isView]);

  return (
    <FormProvider {...methods}>
      <DefaultContentContainer showRequiredFieldsLabel>
        <BreadCrumb {...getBreadcrumbData()} />
        <div
          className="mt-3"
          style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div className="bank-conciliation-row">
            <div>
              <button
                className="btn btn-sucesso button-h35 fontSize-12"
                onClick={() => setIsBankConciliationModalOpen(true)}
              >
                <span>+ Nova Conciliação</span>
              </button>
            </div>
            <BankConciliationSearch
              prefix={prefix}
              usersOptions={usersOptions || []}
              banksOptions={banksOptions}
            />
          </div>
          <div className="mt-2" style={{ width: '100%' }}>
            <Table
              columns={configColumnsConciliation}
              data={rows}
              pageSize={pageLimit}
              page={currentPage}
              onPageChange={setCurrentPage}
              manual
              onPageSizeChange={setPageLimit}
              pages={totalPages}
              loading={isLoading}
            />
          </div>
        </div>
        <BankConciliationModal
          show={isBankConciliationModalOpen}
          isLoading={isLoading}
          onCancel={() => {
            if (isView) {
              methods.setValue('isView', false);
              methods.setValue('viewId', null);
              methods.setValue('informationFile.data', {});
              methods.setValue('selectedTransactions', []);
              methods.setValue('dataFinancialRegister', {});
              methods.setValue('selectedTransactionsConciliation', []);
            }
            setIsBankConciliationModalOpen(false);
          }}
        />
      </DefaultContentContainer>
    </FormProvider>
  );
}
