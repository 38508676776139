import React, { useState } from 'react';
import {
  faBoxOpen,
  faAddressBook,
  faTools,
  faMoneyBillAlt,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import SystemConfiguration from 'repositories/CompanyConfig';

import {
  phoneMask,
  cnpjMask,
  cpfMask,
  currency,
  percentage,
  cepMask,
} from 'client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

import { faCheck, faPrint } from '@fortawesome/free-solid-svg-icons';

import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { getDefaultText } from 'v2/helpers/sheetHelpers';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { bulkCreateBySaleId } from 'v2/repositories/SalesItemsRefusedRepository';

const styles = {
  line: {
    border: '1px solid black',
  },
  mainPanel: {
    marginBottom: '20px',
    marginTop: '20px',
    width: '100%',
    boxShadow: '5px 10px 18px #d6d6d6',
    background: 'white',
  },
  gridCompany: {
    display: 'grid',
    gridTemplateRows: '15% 15% 15% 15%',
    marginTop: '3%',
  },
  spaceMainPanel: {
    padding: '10px',
    marginLeft: '10px',
  },
  fontConteudo: {
    fontSize: '14px',
    color: '#444444',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '350px 350px',
  },
  grid3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    padding: '10px 20px',
  },
  borderRigth: {
    marginRight: '10px',
    borderRight: '1px solid #d6d6d6',
  },
  borderBottom: {
    borderBottom: '1px solid #d6d6d6',
    width: '90%',
  },
  borderBottom2: {
    borderBottom: '1px solid #d6d6d6',
    width: '90%',
  },
  borderBottom1: {
    borderBottom: '1px solid #d6d6d6',
    width: '42%',
  },
  value: {
    marginTop: '90px',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '500px',
    fontSize: '16px',
  },
  marginBottom: {
    marginBottom: '10px',
  },
  imgCompany: {
    width: '140px',
    height: '115px',
    marginLeft: '-10px',
    marginRight: '-15px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const RenderSale = ({
  sale,
  isPlanFreeOrStart,
  isInvalidSalesStatusAndType,
  isDigitalBudgetApproval,
  handlePrint,
  handleOpenApproveBudgetModal,
  showApproveBudgetButton,
  isBudgetApproved,
}) => {
  const company = sale.Company;
  const customer = sale.Customer;
  const companyPhones = company.Phones[0];
  const customerAddress = customer?.Address[0];
  const customerPhone = customer?.Phones[0];
  const vehicle = sale.Vehicle;

  const isActiveDigitalBudgetApproval =
    company.companyConfig.digitalBudgetApproval;

  const initialSaleItems = sale.SalesItems.map((item) => ({
    ...item,
    isApproved: true,
  }));
  const [saleItems, setSaleItems] = useState(initialSaleItems);
  const [refusedItems, setRefusedItems] = useState([]);

  const [showApproveRejectAllModal, setShowApproveRejectAllModal] =
    useState(false);
  const [showResponseBudgetModal, setShowResponseBudgetModal] = useState(false);
  const [hasSendResponseBudget, setHasSendResponseBudget] = useState(false);

  const handleToggleApproveAllItems = () => {
    setShowApproveRejectAllModal(true);
  };

  const handleAcceptApproveRejectAllItems = async () => {
    toggleApproveRejectAllItems();
    setShowApproveRejectAllModal(false);
  };

  const updateSaleItems = (updatedItem) => {
    setSaleItems((prevItems) =>
      prevItems.map((item) =>
        item.id === updatedItem.id
          ? { ...item, isApproved: updatedItem.isApproved }
          : item
      )
    );

    setRefusedItems((prevRefusedItems) => {
      if (!updatedItem.isApproved) {
        return [...prevRefusedItems, { ...updatedItem, createdAt: new Date() }];
      } else {
        return prevRefusedItems.filter((item) => item.id !== updatedItem.id);
      }
    });
  };

  const checkAllItemsStatus = () => {
    const allRefused = saleItems.every((item) => !item.isApproved);
    const allApproved = saleItems.every((item) => item.isApproved);

    return {
      allRefused,
      allApproved,
    };
  };

  const toggleApproveRejectAllItems = () => {
    const { allApproved, allRefused } = checkAllItemsStatus();

    if (allApproved) {
      setSaleItems((prevItems) =>
        prevItems.map((item) => ({ ...item, isApproved: false }))
      );
    } else if (allRefused) {
      setSaleItems((prevItems) =>
        prevItems.map((item) => ({ ...item, isApproved: true }))
      );
    } else {
      setSaleItems((prevItems) =>
        prevItems.map((item) => ({ ...item, isApproved: false }))
      );
    }
  };

  const handleSubmitResponseBudget = async () => {
    try {
      setRefusedItems((prevRefusedItems) =>
        prevRefusedItems.map(({ isApproved, ...rest }) => rest)
      );

      await bulkCreateBySaleId({ items: refusedItems });

      toastr.success('Resposta de orçamento enviado com sucesso!');
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao salvar as informações',
        'Por favor, tente novamente!'
      );
    } finally {
      setShowResponseBudgetModal(false);
      setHasSendResponseBudget(true);
    }
  };

  return (
    <div id="printSale" style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
      <div style={styles.mainPanel}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: company.Url_Logo ? '22% 70%' : '',
            marginLeft: company.Url_Logo ? '' : '15px',
          }}
        >
          {company.Url_Logo ? (
            <div style={styles.spaceMainPanel}>
              <img
                style={styles.imgCompany}
                src={company.Url_Logo}
                alt="OS Digital"
              />
            </div>
          ) : (
            ''
          )}

          <div style={styles.gridCompany}>
            <span>
              <strong>{company.Company_Name}</strong>
            </span>
            <span>{cpfOrCnpjMask(company.Cpf_Cnpj)}</span>
            <span>{company.Trading_Name}</span>
            <span>
              {company.Address[0].Address.split(' ')
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(' ') +
                ', ' +
                company.Address[0].Address_Number +
                ' - ' +
                company.Address[0].Neighborhood.split(' ')
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(' ') +
                ' - ' +
                company.Address[0].City.charAt(0).toUpperCase() +
                company.Address[0].City.slice(1).toLowerCase() +
                '/' +
                company.Address[0].State}
            </span>
            <span>
              {phoneMask(
                companyPhones.Number_Phone1
                  ? companyPhones.Number_Phone1
                  : companyPhones.Number_Phone2
              )}{' '}
              - {company.Email}
            </span>
          </div>
        </div>
      </div>

      <div style={styles.mainPanel}>
        <div style={styles.spaceMainPanel}>
          <span style={{ color: '#1d7391', fontSize: '16px' }}>
            <FontAwesomeIcon
              title="Compartilhar"
              cursor="pointer"
              color="#1d7391"
              icon={faAddressBook}
            />
            <strong style={{ marginLeft: '5px' }}>Dados do Cliente</strong>
          </span>

          <div style={styles.grid}>
            <div style={{ ...styles.fontConteudo, ...styles.borderRigth }}>
              <div>
                {customer.Type === 'Fisica' ? (
                  <span>
                    <strong>Nome Completo</strong>
                  </span>
                ) : (
                  <span>
                    <strong>Razão Social</strong>
                  </span>
                )}
              </div>
              <div style={styles.borderBottom}>
                {customer.Type === 'Fisica' ? (
                  <span>
                    CPF:{' '}
                    <strong>
                      {customer.Cpf_Cnpj
                        ? cpfMask(customer.Cpf_Cnpj)
                        : 'Não informado'}
                    </strong>
                  </span>
                ) : (
                  <span>
                    CNPJ:{' '}
                    <strong>
                      {customer.Cpf_Cnpj
                        ? cnpjMask(customer.Cpf_Cnpj)
                        : 'Não informado'}
                    </strong>
                  </span>
                )}
              </div>
              <div style={styles.borderBottom}>
                {customer.Type === 'Fisica' ? (
                  <span>
                    Nome Completo: <strong>{customer.Company_Name}</strong>
                  </span>
                ) : (
                  <span>
                    Nome Fantasia:{' '}
                    <strong>
                      {customer.Trading_Name
                        ? customer.Trading_Name
                        : 'Não informado'}
                    </strong>
                  </span>
                )}
              </div>
              <div style={styles.borderBottom}>
                Telefone:
                {customerPhone ? (
                  <strong>
                    {phoneMask(
                      customerPhone.Number_Phone1
                        ? customerPhone.Number_Phone1
                        : customerPhone.Number_Phone2
                    )}
                  </strong>
                ) : (
                  ''
                )}
              </div>

              <div style={styles.borderBottom}>
                Email:{' '}
                <strong>
                  {customer.Email ? customer.Email : 'Não informado'}
                </strong>
              </div>
              {customer.Type === 'Fisica' ? (
                ''
              ) : (
                <span>
                  Contato: <strong>{customerPhone.Contact}</strong>
                </span>
              )}
            </div>
            <div style={styles.fontConteudo}>
              <span>
                <strong>Endereço Principal</strong>
              </span>
              <div style={styles.borderBottom}>
                <span>
                  CEP:{' '}
                  <strong>
                    {customerAddress?.Zipcode
                      ? cepMask(customerAddress?.Zipcode)
                      : 'Não informado'}
                  </strong>
                </span>
              </div>
              <div style={styles.borderBottom}>
                Endereço:{' '}
                <strong>
                  {customerAddress?.Address
                    ? `${customerAddress?.Address} - ${customerAddress?.Neighborhood}`
                    : 'Não informado'}{' '}
                </strong>
              </div>
              <div style={styles.borderBottom}>
                Nº:{' '}
                <strong>
                  {customerAddress?.Address_Number
                    ? customerAddress?.Address_Number
                    : 'Não informado'}
                </strong>{' '}
              </div>
              <div>
                Cidade/UF:{' '}
                <strong>
                  {customerAddress?.City
                    ? `${customerAddress?.City} - ${customerAddress?.State}`
                    : 'Não informado'}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      {vehicle && (
        <div style={styles.mainPanel}>
          <div style={styles.spaceMainPanel}>
            <span style={{ color: '#1d7391', fontSize: '16px' }}>
              <FontAwesomeIcon
                title="Compartilhar"
                cursor="pointer"
                color="#1d7391"
                icon={faTools}
              />
              <strong style={{ marginLeft: '5px' }}>Dados do Veículo</strong>
            </span>

            <div style={styles.grid}>
              {vehicle?.vehicleTypeId !== 4 ? (
                <div style={{ ...styles.fontConteudo, ...styles.borderRigth }}>
                  <div style={styles.borderBottom}>
                    {vehicle.License_Plate ? 'Placa' : 'Obj. Manutenção'}:{' '}
                    <strong>
                      {vehicle.License_Plate || vehicle.Maintenance_Object}
                    </strong>
                  </div>
                  <div style={styles.borderBottom}>
                    Veículo: <strong>{vehicle.Model}</strong>
                  </div>
                  <div style={styles.borderBottom}>
                    Marca: <strong>{vehicle.Brand}</strong>
                  </div>
                  <span>
                    KM Atual: <strong>{sale.Kilometers}</strong>
                  </span>
                </div>
              ) : (
                <div style={{ ...styles.fontConteudo, ...styles.borderRigth }}>
                  <div style={styles.borderBottom}>
                    Série/Identificação:
                    <strong>{vehicle.serialIdentification}</strong>
                  </div>
                  <div style={styles.borderBottom}>
                    Marca: <strong>{vehicle.Brand}</strong>
                  </div>
                  <div style={styles.borderBottom}>
                    Modelo: <strong>{vehicle.Model}</strong>
                  </div>
                  <div style={styles.borderBottom}>
                    Ano: <strong>{vehicle.Year_Manufacture}</strong>
                  </div>
                  <div style={styles.borderBottom}>
                    Cor: <strong>{vehicle.Color}</strong>
                  </div>
                  <div style={styles.borderBottom}>
                    QTD. Marchas Dianteiras:{' '}
                    <strong>{vehicle?.BicycleFrontGears?.quantity}</strong>
                  </div>
                  <div style={styles.borderBottom}>
                    QTD. Marchas Traseiras:{' '}
                    <strong>{vehicle?.BicycleRearGears?.quantity}</strong>
                  </div>
                </div>
              )}

              <div style={styles.fontConteudo}>
                <div style={styles.borderBottom2}>
                  <div style={{ marginBottom: '15px' }}>Observação:</div>{' '}
                  <strong>
                    {sale.Comments ? sale.Comments : 'Não informado'}
                  </strong>
                </div>
                <div style={styles.borderBottom2}>
                  <div style={{ marginBottom: '15px' }}>Defeitos: </div>
                  <strong>
                    {sale.Defects ? sale.Defects : 'Não informado'}
                  </strong>
                </div>
                <div>
                  <div style={{ marginBottom: '15px' }}>Avarias:</div>{' '}
                  <strong>
                    {sale.Malfunctions ? sale.Malfunctions : 'Não informado'}
                  </strong>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div style={styles.mainPanel}>
        <div style={styles.spaceMainPanel}>
          <span style={{ color: '#1d7391', fontSize: '16px' }}>
            <FontAwesomeIcon
              title="Compartilhar"
              cursor="pointer"
              color="#1d7391"
              icon={faBoxOpen}
            />
            <strong style={{ marginLeft: '5px' }}>
              {sale.SalesType_id === 1
                ? 'Informações do Orçamento'
                : sale.SalesType_id === 2
                ? 'Informações da Venda'
                : 'Dados da Ordem de Serviço'}
            </strong>
          </span>
          <div>
            <span>
              <strong>Resumo</strong>
            </span>
          </div>

          <div style={{ fontSize: '14px' }}>
            <div style={styles.borderBottom1}>
              <span>
                {sale.SalesType_id === 1
                  ? 'Número do Orçamento:'
                  : sale.SalesType_id === 2
                  ? 'Número da Venda:'
                  : 'Número da Ordem de Serviço:'}
                <strong> {sale.Code}</strong>
              </span>
            </div>
            <div>
              <span>
                {sale.SalesType_id === 1
                  ? 'Data do Orçamento:'
                  : sale.SalesType_id === 2
                  ? 'Data da Venda:'
                  : 'Data da Ordem de Serviço:'}
                <strong>
                  {' '}
                  {format(
                    new Date(getDateOnlyFromDate(sale.Date)),
                    'dd/MM/yyyy'
                  )}
                </strong>
              </span>
            </div>
          </div>

          <div>
            <table
              style={{ width: '100%', margin: '10px', textAlign: 'center' }}
            >
              <thead style={styles.borderBottom}>
                <tr style={styles.borderBottom}>
                  <th style={{ textAlign: 'center', maxWidth: '30px' }}>
                    Código
                  </th>
                  <th style={{ textAlign: 'center' }}>Descrição</th>
                  <th style={{ textAlign: 'center' }}>Quantidade</th>
                  <th style={{ textAlign: 'center' }}>Valor Unit.</th>
                  <th style={{ textAlign: 'center' }}>Desc Unit.</th>
                  <th style={{ textAlign: 'center' }}>Valor Total </th>
                  {!isPlanFreeOrStart &&
                    !isInvalidSalesStatusAndType &&
                    isDigitalBudgetApproval && (
                      <th style={{ textAlign: 'center' }}> </th>
                    )}
                </tr>
              </thead>
              <tbody style={{ fontSize: '14px' }}>
                {saleItems.map((saleItem, index) => (
                  <tr id={`line-${index}`} key={index}>
                    <td
                      style={{
                        maxWidth: '30px',
                        textDecoration: saleItem.isApproved
                          ? ''
                          : 'line-through',
                        color: saleItem.isApproved ? '#000000' : '#D9534F',
                      }}
                    >
                      {!!saleItem.Product_id
                        ? saleItem.Products.Code
                        : saleItem.Services.Code}
                    </td>
                    <td
                      style={{
                        textDecoration: saleItem.isApproved
                          ? ''
                          : 'line-through',
                        color: saleItem.isApproved ? '#000000' : '#D9534F',
                      }}
                    >
                      {saleItem.Description}
                    </td>
                    <td
                      style={{
                        textDecoration: saleItem.isApproved
                          ? ''
                          : 'line-through',
                        color: saleItem.isApproved ? '#000000' : '#D9534F',
                      }}
                    >
                      {saleItem.Quantity}
                    </td>
                    <td
                      style={{
                        textDecoration: saleItem.isApproved
                          ? ''
                          : 'line-through',
                        color: saleItem.isApproved ? '#000000' : '#D9534F',
                      }}
                    >
                      {currency(saleItem.Unit_Value)}
                    </td>
                    <td
                      style={{
                        textDecoration: saleItem.isApproved
                          ? ''
                          : 'line-through',
                        color: saleItem.isApproved ? '#000000' : '#D9534F',
                      }}
                    >
                      {percentage(saleItem.Discount_Value)}
                    </td>
                    <td
                      style={{
                        textDecoration: saleItem.isApproved
                          ? ''
                          : 'line-through',
                        color: saleItem.isApproved ? '#000000' : '#D9534F',
                      }}
                    >
                      {currency(saleItem.Amount)}
                    </td>
                    {!isPlanFreeOrStart &&
                      !isInvalidSalesStatusAndType &&
                      isDigitalBudgetApproval &&
                      !hasSendResponseBudget && (
                        <td>
                          <button
                            className="button"
                            style={{
                              width: '70px',
                              height: '18px',
                              backgroundColor: saleItem.isApproved
                                ? '#D9534F'
                                : '#5CB85C',
                              color: '#FFFFFF',
                              fontSize: '11px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: 'none',
                              borderRadius: '4px',
                            }}
                            onClick={() => {
                              const line = document.querySelector(
                                `#line-${index}`
                              );
                              const tds = line.querySelectorAll(
                                'td:not(:last-child)'
                              );

                              updateSaleItems({
                                ...saleItem,
                                isApproved: !saleItem.isApproved,
                              });

                              tds.forEach((td) => {
                                td.style.textDecoration = saleItem.isApproved
                                  ? ''
                                  : 'line-through';
                                td.style.color = saleItem.isApproved
                                  ? '#000000'
                                  : '#D9534F';
                              });
                            }}
                          >
                            {saleItem.isApproved ? 'Recusar' : 'Aprovar'}
                          </button>
                        </td>
                      )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style={styles.mainPanel}>
        <div style={styles.spaceMainPanel}>
          <span style={{ color: '#1d7391', fontSize: '16px' }}>
            <FontAwesomeIcon
              title="Totais da Venda"
              color="#1d7391"
              icon={faMoneyBillAlt}
            />
            <strong style={{ marginLeft: '5px' }}>Totais</strong>
          </span>
        </div>
        <div style={styles.grid3}>
          <div style={styles.flexColumn}>
            <span>
              <strong>Total:</strong> {currency(sale.Final_Value)}
            </span>
            <span>
              <strong>Desconto:</strong>{' '}
              {currency(!sale.Discount_Value ? 0 : sale.Discount_Value)} (
              {percentage(
                !sale.Discount_Value_Percentage
                  ? 0
                  : sale.Discount_Value_Percentage
              )}
              )
            </span>
          </div>

          <div style={styles.flexColumn}>
            <span>
              <strong>Total de Produtos:</strong>{' '}
              {currency(!sale.Product_Value ? 0 : sale.Product_Value)}
            </span>
            <span>
              <strong>Desconto:</strong>{' '}
              {currency(!sale.Discount_Products ? 0 : sale.Discount_Products)} (
              {percentage(
                !sale.Discount_Products_Percentage
                  ? 0
                  : sale.Discount_Products_Percentage
              )}
              )
            </span>
          </div>

          <div style={styles.flexColumn}>
            <span>
              <strong>Total de Serviços:</strong>{' '}
              {currency(!sale.Service_Value ? 0 : sale.Service_Value)}
            </span>
            <span>
              <strong>Desconto:</strong>{' '}
              {currency(!sale.Discount_Services ? 0 : sale.Discount_Services)} (
              {percentage(
                !sale.Discount_Services_Percentage
                  ? 0
                  : sale.Discount_Services_Percentage
              )}
              )
            </span>
          </div>
        </div>
      </div>

      {(!!sale.Company.defaultSalesOrderFooterText ||
        !!sale.Company.defaultOrcamentFooterText) && (
        <div style={styles.mainPanel}>
          <div style={styles.spaceMainPanel}>
            <span style={{ fontSize: '14px' }}>
              {getDefaultText(sale.SalesType.Description, sale)}
            </span>
          </div>
        </div>
      )}

      <div
        className="no-print"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
        }}
      >
        {!isPlanFreeOrStart &&
          !isInvalidSalesStatusAndType &&
          isActiveDigitalBudgetApproval &&
          !hasSendResponseBudget &&
          !isBudgetApproved && (
            <button
              className="button button-h35 fontSize-12"
              onClick={handleToggleApproveAllItems}
              style={{
                backgroundColor: checkAllItemsStatus().allRefused
                  ? '#5CB85C'
                  : '#D9534F',
                color: '#FFFFFF',
                border: 'none',
              }}
            >
              <FontAwesomeIcon
                color="#fff"
                icon={checkAllItemsStatus().allRefused ? faCheck : faXmark}
                className="option-icon"
                size={'lg'}
              />
              {checkAllItemsStatus().allRefused
                ? 'Aprovar todos os itens'
                : 'Recusar todos os itens'}
            </button>
          )}
        <button
          className="button button-h35 fontSize-12"
          onClick={() => handlePrint()}
          style={{
            backgroundColor: '#f0ad4e',
            color: '#FFFFFF',
            border: 'none',
          }}
        >
          <FontAwesomeIcon
            color="#fff"
            icon={faPrint}
            className="option-icon"
          />
          Imprimir
        </button>
        {!isPlanFreeOrStart &&
          !isInvalidSalesStatusAndType &&
          isDigitalBudgetApproval &&
          !hasSendResponseBudget && (
            <button
              className="button button-h35 fontSize-12"
              onClick={() => setShowResponseBudgetModal(true)}
              style={{
                backgroundColor: '#5CB85C',
                color: '#FFFFFF',
                border: 'none',
              }}
            >
              Enviar resposta
            </button>
          )}
        {!isActiveDigitalBudgetApproval && (
          <button
            className="button button-h35 fontSize-12"
            onClick={() => handleOpenApproveBudgetModal()}
            disabled={isDigitalBudgetApproval}
            style={{
              backgroundColor: '#5CB85C',
              color: '#FFFFFF',
              border: 'none',
            }}
          >
            <FontAwesomeIcon
              color="#fff"
              icon={faCheck}
              className="option-icon"
            />
            Aprovar Orçamento
          </button>
        )}
      </div>

      <Modal
        show={showApproveRejectAllModal}
        onHide={() => setShowApproveRejectAllModal(false)}
        dialogClassName="modal-40w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>
              {checkAllItemsStatus().allRefused
                ? 'Aprovação de todos os itens'
                : 'Recusa de todos os itens'}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkAllItemsStatus().allRefused ? (
            <>
              <p>Você tem certeza que deseja aprovar todos os itens?</p>
              <p>
                Mesmo aprovando, a resposta não foi enviada para o
                estabelecimento, sendo necessário enviar. Se houver a
                necessidade, poderá reprovar novamente.
              </p>
            </>
          ) : (
            <>
              <p>Você tem certeza que deseja recusar todos os itens?</p>
              <p>
                Mesmo recusando, a resposta não foi enviada para o
                estabelecimento, sendo necessário enviar. Se houver a
                necessidade, poderá aprovar novamente.
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end gap-050">
            <button
              className="button button-h35 button-red"
              onClick={() => setShowApproveRejectAllModal(false)}
            >
              Não
            </button>
            <button
              className="button button-h35 button-green"
              onClick={handleAcceptApproveRejectAllItems}
            >
              Sim
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showResponseBudgetModal}
        onHide={() => setShowResponseBudgetModal(false)}
        dialogClassName="modal-40w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Resposta de aprovação/recusa de orçamento</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Você tem certeza que deseja responder o orçamento dessa forma?</p>
          <p>
            Ao enviar a resposta, não poderá mais ser possível alterar. Qualquer
            alteração, deverá entrar em contato com o estabelecimento informando
            a alteração.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end gap-050">
            <button
              className="button button-h35 button-red"
              onClick={() => setShowResponseBudgetModal(false)}
            >
              Não
            </button>
            <button
              className="button button-h35 button-green"
              onClick={handleSubmitResponseBudget}
            >
              Sim
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RenderSale;
