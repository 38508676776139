import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useParams, withRouter } from 'react-router-dom';
import { format, subMonths, isBefore } from 'date-fns';

import sobreRodasLogo from 'assets/img/sobre-rodas-logo.png';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SaleInvoice from './SaleInvoice';
import SalesTableActions from './SalesTableActions';

import Card from 'components/Card/Card.jsx';
import SearchInput from 'components/SearchInput';
import Button from 'client/components/CustomButton/Button.jsx';
import BlockSalesModals from 'client/components/BlockSalesModals';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import UpgradeFreePlanModal from '../../components/UpgradeFreePlanModal';
import UnavailableFeatureModal from '../../components/UnavailableFeatureModal';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import { cashierBankHistoricValidations } from 'client/components/BlockSalesModals/actions';

import './styles.css';
import useDebounce from 'hooks/useDebounce';
import constants from '../../../utils/constants';
import { useAuth } from '../../../contexts/auth';
import useFilters from '../../../hooks/useFilters';
import salesRepository from '../../../repositories/Sales';
import { isDefaultCustomer } from 'utils/isDefaultCustomer';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';

import CompanyRepository from '../../../repositories/Companies';
import employeesRepository from 'repositories/Employees';

import environmentConfig from 'config';
import { formatLicensePlate } from 'client/components/ToNormalize/ToNormalize';

const HomeSales = ({ history }) => {
  // Configs
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [status, setStatus] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [type, setType] = useState('');
  const [query, setQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  // Data
  const [sales, setSales] = useState([]);
  const [sale, setSale] = useState(null);

  const [selectedSale, setSelectedSale] = useState({});

  // Modals
  const [blockTax, setBlockTax] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isUpgradeFreePlanModalOpen, setIsUpgradeFreePlanModalOpen] =
    useState(false);
  const [issueInvoicesAutomatically, setIssueInvoicesAutomatically] =
    useState(false);

  // Contexts
  const { companyId, company } = useAuth();
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  // Consts
  const dispatch = useDispatch();
  const debounceSaveSearchQuery = useDebounce(setSearchQuery, 800);
  const { saleReducer } = useSelector((state) => state);
  const { finishedSaleId } = saleReducer;
  const [newFinishedSaleId, setNewFinishedSaleId] = useState(null);
  const salesQuantityBeforeOneMonth = sales.filter((sale) =>
    isBefore(new Date(sale.Date), subMonths(new Date(), 1))
  ).length;

  const [sellerOptions, setSellerOptions] = useState([]);
  const [selectedSaleId, setSelectedSaleId] = useState(null);

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!!companyId) {
      loadSellers();
      loadIssueInvoicesAutomatically();
      if (isFirstRender) {
        loadSales({ page: 0, pageSize });
      }
    } else {
      setNoCompanyModal(true);
    }

    setIsFirstRender(false);
  }, []);

  // Use effects
  useEffect(() => {
    if (!isFirstRender && !!companyId) {
      loadSales({ page: 0, pageSize });
    }
  }, [status, type, searchQuery, initialDate, finalDate, sellerId]);

  useEffect(() => {
    if (isPlanFree) {
      setInitialDate(format(subMonths(new Date(), 1), 'yyyy-MM-dd'));
    }
  }, [isPlanFree]);

  useEffect(() => {
    if (finishedSaleId) {
      setNewFinishedSaleId(finishedSaleId);
    }
    if (sales.length > 0 && newFinishedSaleId) {
      const sale = sales.filter((sale) => sale.id === newFinishedSaleId);
      if (sale.length > 0 && issueInvoicesAutomatically) {
        handleOpenInvoiceModal(sale[0]);
      }
    }
  }, [sales, finishedSaleId]);
  // Async functions
  async function loadSales(state) {
    setLoading(true);

    try {
      const { sales, count } = await salesRepository.getAllByCompany(
        companyId,
        {
          limit: state.pageSize,
          page: state.page + 1,
          query: searchQuery,
          initialDate,
          finalDate,
          type,
          sellerId,
          status,
        }
      );

      const serializedSales = sales.map((sale) => ({
        ...sale,
        Type: sale.SalesType.Description,
        Status: sale.SalesStatus.Description,
      }));

      setPages(Math.ceil(count / pageSize));
      setSales(serializedSales);
    } catch (err) {
      return toastr.warning(
        'Ocorreu um erro ao carregar as vendas. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  const loadSellers = async () => {
    try {
      if (companyId) {
        const response =
          await employeesRepository.getEmployeesThatPerformServicesOrSellProducts(
            companyId,
            {
              isActive: 1,
              sellProduct: 1,
              performService: 1,
            }
          );

        const employeesThatSellProducts = response.data?.filter(
          (employee) => employee?.Position.sellProducts
        );

        setSellerOptions(employeesThatSellProducts);
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Vendedores',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  async function loadIssueInvoicesAutomatically() {
    const { issueInvoicesAutomatically } = await CompanyRepository.getTaxData(
      companyId
    );
    setIssueInvoicesAutomatically(issueInvoicesAutomatically);
  }

  async function redirectSobreRodas() {
    try {
      const sobreRodasRegister =
        await CompanyRepository.checkSobreRodasRegisterByCnpj(company.Cpf_Cnpj);
      return window.open(
        `${environmentConfig.sobreRodasWebEndpoint}/auto-login?cnpj=${sobreRodasRegister.data.cpfCnpj}&password=98LS34RiwAHw${sobreRodasRegister.data.password}vJab4lj58MBM&sourceId=2`,
        '_blank'
      );
    } catch (err) {
      console.log(err);
      return window.open(
        `${environmentConfig.sobreRodasWebEndpoint}/provider/login', '_blank`
      );
    }
  }

  function handleRedirectToSale(flagged, sale) {
    if (flagged || selectedSaleId === sale.id) {
      return history.push(`sale#${sale.id}`, { saleId: sale.id });
    }
  }

  // Synchronus functions
  async function handleNewSale() {
    if (company?.hasCashierControl) {
      const isValid = await dispatch(cashierBankHistoricValidations(companyId));
      if (isValid.payload) return history.push(constants.ROUTES.SALE);
    } else {
      return history.push(constants.ROUTES.SALE);
    }
  }

  async function handleEditSale(sale) {
    setSelectedSaleId(sale.id);
    if (company?.hasCashierControl) {
      const isValid = await dispatch(cashierBankHistoricValidations(companyId));
      if (isValid.payload) {
        return handleRedirectToSale(true, sale);
      }
    } else {
      return handleRedirectToSale(true, sale);
    }
  }

  function SaleTypeButton({ type }) {
    const colors = {
      Orçamento: '#363636',
      Venda: 'purple',
      PDV: '#ffa834',
      'Ordem de Serviço': '#00008B',
    };

    return (
      <>
        <Button
          id="botaoReactTable"
          style={{
            zIndex: '0',
            width: '110px',
            height: '100%',
            backgroundColor: colors[`${type}`],
          }}
        >
          {type}
        </Button>{' '}
      </>
    );
  }

  function SaleStatusButton({ status }) {
    const colors = {
      'Em Aberto(a)': '#FFC40D',
      'Cancelado(a)': 'red',
      'Finalizado(a)': 'green',
      'Financiamento em Análise': '#5bc0de',
      'Financiamento Aprovado': '#428bca',
      'Financiamento Rejeitado': '#f0ad4e',
      'Aguardando 1º Pagamento': '#0d47a1',
      'Financiamento Expirado': '#ff6900 ',
    };

    const FormatedStatus = ({ status }) => {
      switch (status) {
        case 'Financiamento em Análise':
          return (
            <>
              Financiamento <br /> em Análise
            </>
          );
        case 'Financiamento Aprovado':
          return (
            <>
              Financiamento <br /> Aprovado
            </>
          );
        case 'Financiamento Rejeitado':
          return (
            <>
              Financiamento <br /> Rejeitado{' '}
            </>
          );
        case 'Aguardando 1º Pagamento':
          return (
            <>
              Aguardando <br /> 1º Pagamento{' '}
            </>
          );
        case 'Financiamento Expirado':
          return (
            <>
              Financiamento <br /> Expirado{' '}
            </>
          );
        default:
          return <>{status}</>;
      }
    };

    return (
      <Button
        id="botaoReactTable"
        style={{
          zIndex: '0',
          width: '100%',
          padding: '2px 5px 2px 5px',
          height: 'auto',
          textAlign: 'center',
          backgroundColor: colors[status],
        }}
      >
        <FormatedStatus status={status} />
      </Button>
    );
  }

  function handleOpenInvoiceModal(sale) {
    if (isPlanFree || isPlanStart) return setBlockTax(true);

    const status = sale.SalesStatus.Description;
    const tipo = sale.SalesType.Description;
    const isFinished = status === constants.SALE_STATUS.FINISHED;
    const isOpen = status === constants.SALE_STATUS.OPEN;
    const isOrcament = tipo === constants.SALE_TYPE.ORCAMENT;
    const isCanceled = status === constants.SALE_STATUS.CANCELED;

    if (isFinished) {
      setSale(sale);
      setIsInvoiceModalOpen(true);
      return;
    }

    if (isOpen) {
      toastr.warning(
        `Finalize ${isOrcament ? 'o' : 'a'} ${tipo} para emissão da nota`
      );
    } else if (isCanceled) {
      toastr.error(
        `Não é possível emitir nota para ${tipo} ${
          isOrcament ? 'cancelado' : 'cancelada'
        }`
      );
    }
  }

  function handleChangeSarchQuery(value) {
    setQuery(value);
    debounceSaveSearchQuery(value);
  }

  return (
    <>
      <Card
        content={
          <div>
            <div className="sales-row">
              <div>
                <ModalAlertCompany
                  show={noCompanyModal}
                  onHide={() => setNoCompanyModal(false)}
                />
                <button
                  className="btn btn-sucesso button-h35 fontSize-12"
                  onClick={() =>
                    !!companyId ? handleNewSale() : setNoCompanyModal(true)
                  }
                >
                  + Nova Venda
                </button>
              </div>
              <SearchInput
                placeholder="Pesquisa por Venda/OS, Cliente, Placa/Obj. de Manutenção ou Pedido"
                onChange={(e) => handleChangeSarchQuery(e.target.value)}
                value={query}
                style={{ fontSize: '12px' }}
              />
              <fieldset>
                <span className="typography__text-2">Vendedor: </span>
                <select
                  name="sellerId"
                  className="form-control foco-input fontSize-12"
                  value={sellerId}
                  onChange={(e) => {
                    setSellerId(e.target.value);
                  }}
                >
                  <option value="">Todos</option>
                  {sellerOptions.map((sellerOptions) => (
                    <option key={sellerOptions.id} value={sellerOptions.id}>
                      {sellerOptions.name}
                    </option>
                  ))}
                </select>
              </fieldset>
            </div>
            <div className="sales-row">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  width: '95%',
                }}
              >
                <fieldset>
                  <span className="typography__text-2">Data Inicial:</span>
                  <input
                    className="form-control foco-input fontSize-12"
                    type="date"
                    name="dataInicial"
                    value={initialDate}
                    disabled={isPlanFree}
                    onChange={(e) => setInitialDate(e.target.value)}
                  />
                </fieldset>
                <fieldset>
                  <span className="typography__text-2">Data Final:</span>
                  <input
                    className="form-control foco-input fontSize-12"
                    type="date"
                    name="dataFinal"
                    value={finalDate}
                    disabled={isPlanFree}
                    onChange={(e) => setFinalDate(e.target.value)}
                  />
                </fieldset>
                <fieldset>
                  <span className="typography__text-2">Tipo:</span>
                  <select
                    className="form-control foco-input fontSize-12"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="">Todos</option>
                    {constants.SALES_TYPE.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </fieldset>
                <fieldset>
                  <span className="typography__text-2">Status:</span>
                  <select
                    className="form-control foco-input fontSize-12"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value="">Todos</option>
                    <option value="3">Em Aberto</option>
                    <option value="2">Cancelada</option>
                    <option value="5">Finalizada</option>
                    <option value="6">Financiamento em Análise</option>
                    <option value="7">Financiamento Aprovado</option>
                    <option value="8">Financiamento em Rejeitado</option>
                  </select>
                </fieldset>
              </div>
              {!isPlanFree && (
                <img
                  className="sobre-rodas-logo"
                  src={sobreRodasLogo}
                  alt="Logo do Sobre Rodas"
                  title="Clique aqui para acessar Sobre Rodas"
                  onClick={() => redirectSobreRodas()}
                  style={{
                    width: '120px',
                    height: '50px',
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
            {!!salesQuantityBeforeOneMonth && isPlanFree && (
              <span
                className="sales-page-sales-archived-badge"
                onClick={() => setIsUpgradeFreePlanModalOpen(true)}
                style={{ marginBottom: '10px' }}
              >
                {salesQuantityBeforeOneMonth > 1
                  ? `Você tem ${salesQuantityBeforeOneMonth} vendas arquivadas, clique aqui.`
                  : `Você tem ${salesQuantityBeforeOneMonth} venda arquivada, clique aqui.`}
              </span>
            )}
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '11px',
              }}
              data={sales}
              columns={[
                {
                  Header: 'Data',
                  accessor: 'Date',
                  headerClassName: 'text-center',
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto 0',
                  },
                  className: 'texto',
                  width: 100,
                  Cell: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      {format(new Date(props.value), 'dd/MM/yyyy')}
                    </div>
                  ),
                },
                {
                  Header: 'Tipo',
                  accessor: 'Type',
                  headerClassName: 'text-center',
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto 0',
                  },
                  width: 120,
                  className: 'texto',
                  Cell: (props) => {
                    const sellerNameSplited =
                      props.original?.Seller?.name.split(' ') || [];
                    const sellerFirstName = sellerNameSplited[0] || '';
                    const sellerLastNameAbbr = sellerNameSplited[1]
                      ? `${sellerNameSplited[1][0]}.`
                      : '';

                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <SaleTypeButton type={props.value} />
                        <span>
                          {sellerFirstName} {sellerLastNameAbbr}{' '}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  Header: 'N.º Venda',
                  accessor: 'Code',
                  headerClassName: 'text-center',
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto 0',
                  },
                  className: 'texto',
                  width: 85,
                  Cell: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <button
                        title="Clique para editar"
                        className="btn-link"
                        onClick={() => handleEditSale(props.original)}
                      >
                        {props.value}
                      </button>
                      <span style={{ fontSize: '11px', fontWeight: 'bold' }}>
                        {props.original.customerRequest
                          ? `Ped: ${props.original.customerRequest}`
                          : ''}
                      </span>
                    </div>
                  ),
                },
                {
                  Header: 'Cliente',
                  accessor: 'Customer.Company_Name',
                  headerClassName: 'text-center',
                  className: 'texto',
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto 0',
                  },
                  Cell: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          color: isDefaultCustomer(props.original.Customer)
                            ? 'red'
                            : 'unset',
                        }}
                      >
                        {props.value}
                      </span>
                    </div>
                  ),
                },
                {
                  Header: 'Placa/Obj. Manut',
                  accessor: 'License_Pl<ate',
                  headerClassName: 'text-center',
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto 0',
                  },
                  width: 130,
                  className: 'texto',
                  Cell: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <span>
                        {props.original.Vehicle?.Maintenance_Object
                          ? props.original.Vehicle?.Maintenance_Object
                          : formatLicensePlate(props.original.License_Plate)}
                      </span>
                      <span style={{ textTransform: 'uppercase' }}>
                        <strong>
                          {props.original.Vehicle?.Model?.slice(0, 20) || ''}
                        </strong>
                      </span>
                    </div>
                  ),
                },
                {
                  Header: 'Valor',
                  accessor: 'Final_Value',
                  headerClassName: 'text-center',
                  className: 'texto',
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto 0',
                  },
                  width: 120,
                  Cell: (props) => <a>{currency(props.value)}</a>,
                },
                {
                  Header: 'Status',
                  accessor: 'Status',
                  headerClassName: 'text-center',
                  className: 'texto',
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto 0',
                  },
                  width: 112,
                  Cell: (props) => <SaleStatusButton status={props.value} />,
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-center',
                  filterable: false,
                  className: 'texto',
                  width: 205,
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto 0',
                  },
                  Cell: (props) => (
                    <SalesTableActions
                      sale={props.original}
                      setSelectedSaleId={setSelectedSaleId}
                      selectedSaleId={selectedSaleId}
                      loadSales={() =>
                        loadSales({
                          page,
                          pageSize,
                          query,
                          initialDate,
                          finalDate,
                          type,
                          status,
                        })
                      }
                    />
                  ),
                },
                {
                  Header: 'NF',
                  accessor: 'id',
                  headerClassName: 'text-center',
                  filterable: false,
                  className: 'texto',
                  width: 50,
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto 0',
                  },
                  Cell: ({ original, index }) => (
                    <FontAwesomeIcon
                      onClick={() => handleOpenInvoiceModal(original)}
                      style={{
                        cursor: 'pointer',
                        width: '1.5em',
                        height: '1.5em',
                        color:
                          !!original.nfse_id ||
                          !!original.nfce_id ||
                          !!original.nfe_id ||
                          !!original.sat_id
                            ? '#629c44'
                            : '#aaaaaa',
                      }}
                      title="Emitir/Visualizar Notas Fiscais"
                      icon={faReceipt}
                    />
                  ),
                },
              ]}
              sortable
              showPagination
              pages={pages}
              loading={loading}
              defaultPageSize={10}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[{ id: 'Date', desc: true }]}
              manual
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma venda encontrada"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
              onFetchData={(state) => {
                if (state.page !== page || state.pageSize !== pageSize) {
                  loadSales(state);
                }
              }}
              onPageChange={(page) => setPage(page)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </div>
        }
      />

      {isInvoiceModalOpen && !!sale && (
        <SaleInvoice sale={sale} onHide={() => setIsInvoiceModalOpen(false)} />
      )}

      <UnavailableFeatureModal
        show={blockTax}
        onHide={() => setBlockTax(false)}
        unavailableFeatureText={
          `O plano ${
            isPlanFree ? 'free' : 'start'
          } não possui emissão de documento fiscal. ` +
          'Entre em contato com o nosso comercial e solicite a troca de plano e o módulo fiscal.'
        }
      />

      {!selectedSaleId && (
        <BlockSalesModals
          onSubmit={() => history.push(constants.ROUTES.SALE)}
          onCancel={() => setSelectedSaleId(null)}
        />
      )}

      <UpgradeFreePlanModal
        show={isUpgradeFreePlanModalOpen}
        onCancel={() => setIsUpgradeFreePlanModalOpen(false)}
      />
    </>
  );
};

export default withRouter(HomeSales);
